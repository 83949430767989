/**
 * Module Audio Page d'accueil : Liste les 4 derniers audios uploader et une selection des catégories.
 * Copyright: © 2021 Remote Monkey S.A.S.U http://remote-monkey.fr
 * https://blog.logrocket.com/4-ways-to-render-large-lists-in-react/
 */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { audio } from '../../../redux/audio/actionAudio';
import axios from 'axios';
import LoadingPlaceHolder from '../../functional/LoadingPlaceHolder/LoadingPlaceHolderAudioHome';
import H2 from '../../functional/H2';
import Select from '../../functional/selectAudio';

export default function AudiosHomePage() {
	const dispatch = useDispatch();

	const [listeDourous, setListeDourous] = useState([]);

	const checkLocalStorageAge = (date) => {
		const today = Date.now();
		const timeDifference = today - date;

		const daysDifference = timeDifference / (1000 * 3600 * 24);
		if (daysDifference >= 1) {
			localStorage.removeItem(`liste_audio`);
			localStorage.removeItem(`liste_audio_storageDate`);
			axios
				.get(
					`https://us-central1-dourousnet-3fcb0.cloudfunctions.net/getAudio?request=dourous`
				)
				.then(function (res) {
					setListeDourous(res.data.listeAudio);
					localStorage.setItem(`liste_audio`, JSON.stringify(res.data.listeAudio));
					localStorage.setItem(`liste_audio_storageDate`, Date.now());
				})
				.catch(function (err) {
					console.log('error get Dourous', err);
				});
		} else {
			setListeDourous(JSON.parse(localStorage.getItem(`liste_audio`)));
		}
	};

	// Get Data
	useEffect(() => {
		// Si on détècte une liste dans le local storage
		if (JSON.parse(localStorage.getItem(`liste_audio`))) {
			if (localStorage.getItem(`liste_audio_storageDate`)) {
				// si la date existe
				const date = localStorage.getItem(`liste_audio_storageDate`);
				checkLocalStorageAge(date);
			}
		} else {
			axios
				.get(
					`https://us-central1-dourousnet-3fcb0.cloudfunctions.net/getAudio?request=dourous`
				)
				.then(function (res) {
					setListeDourous(res.data.listeAudio);
					localStorage.setItem(`liste_audio`, JSON.stringify(res.data.listeAudio));
					localStorage.setItem(`liste_audio_storageDate`, Date.now());
				})
				.catch(function (err) {
					console.log('error get Dourous', err);
				});
		}
	}, []);

	// Création d'un tableau de catégories
	const categoriesDourous = [];
	if (listeDourous) {
		listeDourous.forEach(function (item) {
			if (item.theme === "les 5 piliers de l'islam") {
				item.theme = "Les 5 piliers de l'islam";
			} else if (item.theme === 'Coutumes et innovations ') {
				item.theme = 'Coutumes et innovations';
			} else if (item.theme === 'Actualités & Événements') {
				item.theme = 'Actualités et Événements ';
			}

			if (categoriesDourous.length === 0) {
				categoriesDourous.push(item.theme);
			} else {
				// si deja inclus on retourne
				if (categoriesDourous.includes(item.theme) === true) {
					return;
				} else {
					// sinon on push le theme
					categoriesDourous.push(item.theme);
				}
			}
		});
	}

	// Création d'un tableau avec les 4 derniers Audios
	const lastDourous = [];
	let i = 0;
	if (listeDourous) {
		listeDourous.forEach(function (item) {
			if (i <= 3) {
				lastDourous.push(item);
				i++;
			} else {
				return;
			}
		});
	}

	// Select Sound
	const playSound = (url, title, date) => {
		const sound = {
			url: url,
			title: title,
			date: date
		};

		dispatch(audio(sound));
	};

	const contentDourous =
		lastDourous.length > 0 ? (
			<div className="grid sm:grid-cols-1 md:grid-cols-2 gap-x-3 gap-y-3">
				{lastDourous &&
					lastDourous.map((item, index) => (
						<div key={index} className="audio animate">
							<button
								onClick={() => playSound(item.mp3_url, item.title, item.date)}
								className={`h-full relative typoSecondary bg-primary hover:bg-secondary w-full flex flex-col items-center justify-center text-lg text-white px-4 py-16 rounded-2xl hover-ride-top-large animate`}
							>
								<div>{item.title}</div>
							</button>
						</div>
					))}
			</div>
		) : (
			<LoadingPlaceHolder />
		);

	return (
		<>
			<H2 align="left">
				<span className="capitalize typoSecondary text-5xl">Audios récents</span>
			</H2>
			<div className="lg:flex lg:items-center lg:justify-items-center">
				<div className="relative z-0 lg:w-8/12">{contentDourous}</div>
				<div className="lg:w-4/12 px-4 lg:pl-10 py-10 relative z-10">
					<H2 align="left">
						<span className="capitalize typoSecondary text-5xl">Dourous</span>
					</H2>
					<p className="text-sm text-gray-400 lg:text-left text-center">
						Retrouvez plusieurs thèmes classés dans différentes catégories en cliquant
						sur le menu.
					</p>
					<Select
						label={'Choisir une catégorie'}
						array={categoriesDourous.sort()}
						ico={'volume-up'}
					/>
				</div>
			</div>
		</>
	);
}
