/**
 * Module de selection basique
 * Copyright: © 2021 Remote Monkey S.A.S.U http://remote-monkey.fr
 * https://blog.logrocket.com/4-ways-to-render-large-lists-in-react/
 */
import React from 'react';
import { Fragment, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';

function classNames(...classes) {
	return classes.filter(Boolean).join(' ');
}

export default function Select(props) {
	const { label, array, ico } = props;

	const [selected, setSelected] = useState();

	return (
		<Listbox value={selected} onChange={setSelected}>
			{({ open }) => (
				<>
					{/* <Listbox.Label className="block text-sm font-medium text-gray-700">{label}</Listbox.Label> */}
					<div className="mt-1 relative">
						<Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-pointer focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-secondary sm:text-sm">
							<span className="flex items-center">
								<div className="flex items-center justify-center h-6 w-6 rounded-full bg-gray-200 text-gray-500 text-xxs">
									<FontAwesomeIcon icon={['fa', `${ico}`]} />
								</div>
								<span className="ml-3 block truncate">
									{selected ? selected : label}
								</span>
							</span>
							<span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
								<div
									className="flex flex-col items-center justify-center text-gray-400 mr-1"
									aria-hidden="true"
								>
									<FontAwesomeIcon icon={['fa', 'angle-up']} />
									<FontAwesomeIcon icon={['fa', 'angle-down']} />
								</div>
							</span>
						</Listbox.Button>

						<Transition
							show={open}
							as={Fragment}
							leave="transition ease-in duration-100"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Listbox.Options
								static
								className="absolute mt-1 mx-0 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
							>
								{array.map((item, index) => (
									<Link key={index} to={`/audios?cat=${item}`}>
										<Listbox.Option
											className={({ active }) =>
												classNames(
													active
														? 'text-white bg-secondary'
														: 'text-gray-900',
													'cursor-pointer select-none relative py-2 pl-3 pr-9'
												)
											}
											value={item}
										>
											{({ selected, active }) => (
												<>
													<div className="flex items-center">
														<div className="flex items-center justify-center h-6 w-6 rounded-full bg-gray-200 text-gray-500 text-xxs">
															<FontAwesomeIcon
																icon={['fa', 'volume-up']}
															/>
														</div>
														<span
															className={classNames(
																selected
																	? 'font-semibold'
																	: 'font-normal',
																'ml-3 block truncate'
															)}
														>
															{item}
														</span>
													</div>

													{selected ? (
														<span
															className={classNames(
																active
																	? 'text-white'
																	: 'text-secondary',
																'absolute inset-y-0 right-0 flex items-center pr-4'
															)}
														>
															<FontAwesomeIcon
																icon={['fa', 'check']}
															/>
														</span>
													) : null}
												</>
											)}
										</Listbox.Option>
									</Link>
								))}
							</Listbox.Options>
						</Transition>
					</div>
				</>
			)}
		</Listbox>
	);
}
