/**
 * Home Page
 * Copyright: © 2021 Remote Monkey S.A.S.U http://remote-monkey.fr
 */
import React from 'react';
import Layout from '../components/body/layout';
import Seo from '../components/seo';
import Container from '../components/body/Container';
import ListLastVideos from '../components/content/ListeLastVideos';
import flowers from '../images/svg/flowers-bg.svg';
import ListeTopVideos from '../components/content/ListeTopVideos';
import AudiosHomePage from '../components/marketing/audios/AudiosHomePage';
import Newsletter from '../components/marketing/newsletter/Newsletter';

const Index = () => {
	return (
		<Layout header="heroTop">
			<Seo
				title="Nader Abou Anas - Imam, Conférencier et Enseignant"
				description="Le frère Nader Abou Anas est un demandeur de science (talib ‘ilm) et conférencier. Depuis le début des années 2000, le frère consacre la majorité de son temps à talab el ‘ilm (l’apprentissage des sciences islamiques) et à la da’wah (propagation du message de l’islam). Son grand amour pour la religion..."
			/>

			<Container>
				<ListLastVideos template={'homePage'} />
				<AudiosHomePage />
			</Container>

			<Container>
				<div className="relative bg-primary mb-10 rounded-2xl mt-10">
					<div
						className="absolute z-0 w-full h-full opacity-70"
						style={{
							backgroundImage: `url(${flowers})`,
							backgroundSize: '60%'
						}}
					></div>
					<div className="relative z-10 p-10">
						<Newsletter align="center" description="hidden" type="inline" />
					</div>
				</div>
			</Container>

			<div className="bg-gray-100 pt-10 pb-5 mt-5 mb-0">
				<Container>
					<ListeTopVideos />
				</Container>
			</div>
		</Layout>
	);
};

export default Index;
