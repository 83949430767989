/**
 * *Rôle : Liste les articles du blog wp
 * Copyright: © 2021 Remote Monkey S.A.S.U http://remote-monkey.fr
 */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ProgressiveImage from 'react-progressive-image';
import { Link } from 'gatsby';
import 'moment/locale/fr';
import moment from 'moment';
import H2 from '../functional/H2';
import PlaceHolder from '../functional/LoadingPlaceHolder/LoadingPlaceHolderVideosSimilaires';

// SWIPER
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import '../../styles/slider-swiper/globalStyle.scss';

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const ListeVideosByCatRandom = () => {
	const [listVideos, setListVideos] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);

		axios
			.get(
				`https://us-central1-dourousnet-3fcb0.cloudfunctions.net/getAllVideoFeatured?per_page=50&page=1`
			)
			.then(function (res) {
				setListVideos(res.data.data);
				setLoading(false);
			})
			.catch(function (err) {
				console.log('error get Featured Vidéo => ', err);
				setLoading(false);
			});
	}, []);

	const createUri = (defaultUri) => {
		const uri = defaultUri.replace('/videos/', '');
		return uri;
	};

	let listeVideo;
	if (listVideos && listVideos.length > 0) {
		listeVideo = listVideos.map((video, index) =>
			!video.is_playable ? null : (
				<SwiperSlide key={index}>
					<Link to={`/watch-video/${createUri(video.uri)}`}>
						<div className="w-full mb-5 lg:mb-0">
							<div className="w-full relative">
								<div className="relative overflow-hidden rounded-t-xl rounded-b-md h-44 max-h-44 w-full bg-white">
									<ProgressiveImage
										delay={500}
										src={video.pictures.sizes[3].link}
										placeholder={video.pictures.sizes[2].link}
									>
										{(src, loading) => (
											<img
												className="absolute top-0 left-0 w-full h-full hover-img-scale animate"
												style={{
													filter: loading ? 'blur(5px)' : 'blur(0px)',
													objectFit: 'cover',
													objectPosition: 'center center'
												}}
												src={src}
												alt=""
											/>
										)}
									</ProgressiveImage>
								</div>
								<svg
									className="h-5 w-5 rounded-full p-3 bg-gray-100 absolute -bottom-4 left-5 text-secondary"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									strokeWidth="2"
									stroke="currentColor"
									fill="#6c62af"
									strokeLinecap="round"
									strokeLinejoin="round"
								>
									<polygon points="5 3 19 12 5 21 5 3" />
								</svg>
							</div>
						</div>

						<div className="text-gray-400 text-sm mt-5 flex items-center justify-start">
							<svg
								className="h-3 w-3 mr-2"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								strokeWidth="2"
								stroke="currentColor"
								fill="none"
								strokeLinecap="round"
								strokeLinejoin="round"
							>
								<rect x="3" y="4" width="18" height="18" rx="2" ry="2" />{' '}
								<line x1="16" y1="2" x2="16" y2="6" />{' '}
								<line x1="8" y1="2" x2="8" y2="6" />{' '}
								<line x1="3" y1="10" x2="21" y2="10" />
							</svg>
							{moment(video.created_time).format('LL')}
						</div>

						<div className="font-bold text-lg">{video.name}</div>
					</Link>
				</SwiperSlide>
			)
		);
	}

	return (
		<>
			<div className="px-2 sm:px-5 lg:px-10">
				<H2 className="mb-10">On vous recommande</H2>

				{loading ? (
					<PlaceHolder />
				) : listVideos.length > 1 ? (
					<Swiper
						spaceBetween={15}
						slidesPerView={1}
						navigation={{ clickable: true }}
						pagination={{
							clickable: true,
							dynamicBullets: true
						}}
						// scrollbar={{ draggable: false }}
						// onSwiper={(swiper) => console.log(swiper)}
						// onSlideChange={() => console.log('slide change')}
						breakpoints={{
							// when window width is >= 320px
							20: {
								slidesPerView: 1,
								spaceBetween: 20
							},
							// when window width is >= 480px
							768: {
								slidesPerView: 2,
								spaceBetween: 30
							},
							// when window width is >= 640px
							1024: {
								slidesPerView: 3,
								spaceBetween: 40
							}
						}}
					>
						{listeVideo}
					</Swiper>
				) : (
					<></>
				)}
			</div>
		</>
	);
};

export default ListeVideosByCatRandom;
